import Head from 'next/head'
import useTheme from '@mui/styles/useTheme'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { TOOLBAR_HEIGHT } from '@/styles/theme'
import Header from '../components/header'
import Keyboard from '../components/keyboard'
import IntroNewsletterSlick from '../components/IntroNewsletterSlick'

const Home = () => {
  const theme: any = useTheme()

  return (
    <>
      <Head>
        <title>Unreal Cloud</title>
        <meta name="description" content="CI/CD for Unreal Engine" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header />

      <Container>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          height={`calc(100vh - ${TOOLBAR_HEIGHT}px)`}
        >
          <Typography component="span" fontWeight="lighter" noWrap>
            CI/CD Service for{' '}
            <Typography component="span" fontWeight="normal" color="primary">
              Unreal Engine
            </Typography>
          </Typography>

          <div>
            <Typography
              fontSize={30}
              display="block"
              marginBottom={6}
              letterSpacing={5}
              overflow="visible"
              textAlign="center"
              noWrap
            >
              <Keyboard>W</Keyboard>
              rite Unreal Code
            </Typography>
            <Typography
              fontSize={30}
              letterSpacing={5}
              overflow="visible"
              textAlign="center"
              noWrap
            >
              <Keyboard>A</Keyboard>nd
              <Keyboard>S</Keyboard>treamline Buil<Keyboard>D</Keyboard>s
            </Typography>
          </div>

          <div>
            <IntroNewsletterSlick />
          </div>
        </Grid>
      </Container>
    </>
  )
}

export default Home
