const env = process.env.NEXT_PUBLIC_APP_ENVIRONMENT || 'local'
const cfg = require(`./${env}.json`)

if (env !== 'prod') {
  console.log('Environment:', env)
}

export const environment = cfg.environment
export const appHost = cfg.appHost
export const apiHost = cfg.apiHost
