import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MuiButton from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Image from 'next/image'
import { appHost, environment } from '@/src/config'

import Logo from '@/src/assets/images/UCloudLogo.png'

const Button = styled(MuiButton)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'lighter',
  textDecoration: 'none',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))

const Header = () => (
  <AppBar elevation={0} sx={{ background: 'transparent' }}>
    <Container>
      <Toolbar>
        <Typography fontWeight="bold">
          <ButtonBase href="/" disableRipple>
            <Image src={Logo} alt="Unreal Cloud" height={50} />
            <Box marginLeft={2} component="span" fontSize={20} fontWeight="lighter">
              Unreal Cloud
            </Box>
          </ButtonBase>
        </Typography>

        {environment !== 'prod' && (
          <Typography fontWeight="lighter" marginLeft="auto">
            <Button variant="text" href="/pricing">
              Pricing
            </Button>
            |
            <Button variant="text" href={`${appHost}/login`}>
              Login
            </Button>
            <Button variant="outlined" href={`${appHost}/signup`}>
              Signup
            </Button>
          </Typography>
        )}
      </Toolbar>
    </Container>
  </AppBar>
)

export default Header
